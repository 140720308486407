/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from './components/Navbars/favicon.png'

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Article1 from "./components/Article1";
import Article2 from "./components/Article2";
import Article3 from "./components/Article3";
import Article4 from "./components/Article4";
import Article5 from "./components/Article5";
import LandingPage from "./views/pages/LandingPage.js";
import Project from "./views/pages/Project.js"; 
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import ProjectPage from "./views/pages/Project.js";
import Partners from "./views/pages/Partners.js";
import Outcomes from "./views/pages/Outcomes";
import News from "./views/pages/News";
import { hotjar } from 'react-hotjar';
import PiwikPro from '@piwikpro/react-piwik-pro';
import Article6 from "./components/Article6";
import Article8 from "./components/Article8";
import Article7 from "./components/Article7";

PiwikPro.initialize('d2ecb12e-611d-4a22-a918-7db9d2993af4', 'https://pantheon-project.piwik.pro');
// Check if Hotjar has been initialized before calling its methods


ReactDOM.render(
  <BrowserRouter>
  <Helmet>
        <meta charSet="utf-8"/>
        <title>ARISE</title>
        <link rel="image_src" href="" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="ARISE – ADVANCED AI AND ROBOTICS FOR AUTONOMOUS TASK PERFORMANCE" />
        <meta name="theme-color" content="#008f68" />

  </Helmet>
    <Switch>
      <Switch>
        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
        <Route path="/project" component={ProjectPage} />
        <Route path="/consortium" component={Partners} />
        <Route path="/outcomes" component={Outcomes} />
        <Route path="/news" component={News} />
        <Route path="/Kick-off-Meeting in Athens" component={Article1} />
        <Route path="/Alongside Adra-e. Towards AI-driven autonomous robots" component={Article2} />
        <Route path="/Advancing the Arise Project: ALSYMEXs visit to Les Fermes Nouvelles" component={Article3} />
        <Route path="/ARISE Project Shines at ICRA 2024" component={Article4} />
        <Route path="/ARISE Project Featured in ADRA-e Brochure" component={Article5} />
        <Route path="/Visiting the facilities of our partner JEPCO: Exciting takeaways!" component={Article6} />
        <Route path="/The mission of ARISE is well-spread through the TWI Innovation Network!" component={Article7} />
        <Route path="/ARISE is Well-Spread Through Our Partner UBITECH!" component={Article8} />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
